import React from "react"
import { useState } from "react"
import { Link } from "gatsby"
import "../styles/navbar.css"
import { FaSmileBeam, FaTimesCircle } from "react-icons/fa"

const Navbar = () => {
  const [expanded, setExpanded] = useState(false)
  const showNav = () => {
    setExpanded(!expanded)
  }

  const activeStyles = {
    borderBottom: "4px ridge var(--purple)",
  }

  // Add outline functionality for accessibility when tab key is used

  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      // the "I am a keyboard user" key
      document.body.classList.add("user-is-tabbing")
      window.removeEventListener("keydown", handleFirstTab)
    }
  }

  if (typeof window !== `undefined`) {
    window.addEventListener("keydown", handleFirstTab)
  }

  return (
    <nav>
      <div className="navbar">
        <Link to="/" className="home-anchor">
          <div className="site-title ">The Church of Better Daze</div>
        </Link>
        <button
          className="mobile-nav-button"
          aria-label={"open and close the navigation menu "}
          onClick={showNav}
          aria-expanded={expanded}
        >
          {!expanded ? (
            <FaSmileBeam aria-hidden="true" className="cross-icon" />
          ) : (
            <FaTimesCircle aria-hidden="true" className="cross-icon closeNav" />
          )}
        </button>
      </div>
      <div className={`navbar2 ${expanded ? "show" : "hide"}`}>
        <ul>
          <Link to="/about" activeStyle={activeStyles}>
            <li>Who We Are</li>
          </Link>
          <Link to="/calendar" activeStyle={activeStyles}>
            <li>Tour Dates</li>
          </Link>
          <Link to="/music" activeStyle={activeStyles}>
            <li>Tunes</li>
          </Link>
          <Link to="/photo-video" activeStyle={activeStyles}>
            <li>Pics / Vids</li>
          </Link>
          <a
            href="https://store.boygolden.ca/collections/boy-golden"
            target="_blank"
            rel="noopener noreferrer"
          >
            <li>Shop</li>
          </a>
          <a target="_blank" href="https://boygolden.substack.com">
            <li>Ministerial Musings</li>
          </a>
          <Link target="_blank" href="https://laylo.com/boygolden">
            <li>Join Our Congregation</li>
          </Link>
          <Link to="/contact" activeStyle={activeStyles}>
            <li>Contact Us</li>
          </Link>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
