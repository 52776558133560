import React from "react"
import {
  FaInstagram,
  FaFacebook,
  FaSpotify,
  FaSoundcloud,
  FaYoutubeSquare,
} from "react-icons/fa"
import { SiTiktok } from "react-icons/si"

import "../styles/footer.css"

import Netscape from "../images/netscape.png"
import RetroHitCounter from "react-retro-hit-counter"

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="footer-icons">
          <div className="hit-counter">
            <RetroHitCounter
              className="timer"
              hits={420}
              withBorder={false}
              withGlow={true}
              minLength={2}
              size={35}
              padding={5}
              digitSpacing={3}
              segmentThickness={4}
              segmentSpacing={0.8}
              segmentActiveColor="rgb(27, 192, 210)"
              segmentInactiveColor="#444444"
              backgroundColor="#222222"
              borderThickness={7}
              glowStrength={0.6}
            />
          </div>
          <div className="social-icons">
            <a
              aria-label="go to the boy golden facebook page"
              href="https://www.facebook.com/boygolden"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook aria-hidden="true" alt="Facebook link icon" />
            </a>
            <a
              aria-label="go to the boy golden instagram page"
              href="https://www.instagram.com/boygolden/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram aria-hidden="true" alt="Instagram link icon" />
            </a>
            <a
              aria-label="go to the boy golden soundcloud page"
              href="https://soundcloud.com/user-87012428"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSoundcloud aria-hidden="true" alt="Soundcloud link icon" />
            </a>
            <a
              aria-label="go to the boy golden youtube page"
              href="https://www.youtube.com/channel/UCFuCZ_8LFQa2wTbt-RIWUBQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutubeSquare aria-hidden="true" alt="Youtube link icon" />
            </a>
            <a
              aria-label="go to the boy golden spotify page"
              href="https://open.spotify.com/artist/4oNZapwLKDfR92AX7LbRk1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSpotify aria-hidden="true" alt="Spotify link icon" />
            </a>
            {/* <a
              aria-label="go to the boy golden bandcamp page"
              href="https://yaboygolden.bandcamp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBandcamp aria-hidden="true" alt="bandcamp link icon" />
            </a> */}
            <a
              aria-label="go to the boy golden bandcamp page"
              href="https://www.tiktok.com/@ya.boy.golden?"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiTiktok aria-hidden="true" alt="TikTok link icon" />
            </a>
          </div>
          <div>
            <img
              className="netscape-icon"
              src={Netscape}
              alt="internet explorer 90s logo"
            ></img>
          </div>
        </div>
        <h6>Copyright © 1995 - Church of Better Daze - All Rights Reserved</h6>
      </div>
    </footer>
  )
}

export default Footer
